import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import AuthService from "../../services/auth-service";

const NavBar = () => {
  const [currentUser, setCurrentUser] = useState(undefined);

  const logOut = () => {
    AuthService.logout();
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }
  }, []);
  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">MANAGER</Navbar.Brand>
        {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />  
            <Button variant="outline-light" size="sm">Search</Button>
          </Form> */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          {currentUser ? (
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "250px" }}
              navbarScroll
            >
              <Nav.Link href="/home">Home</Nav.Link>
              {/* <NavDropdown title="Orders" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/order">New Order</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/order">View Orders</NavDropdown.Item>
              </NavDropdown> */}
              <NavDropdown title="Quotes" id="navbarScrollingDropdown1">
                <NavDropdown.Item href="#">New Quote</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#">View Quotes</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Invoices" id="navbarScrollingDropdown2">
                <NavDropdown.Item href="/invoice">New Invoice</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/invoiceList">
                  View Invoices
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sales" id="navbarScrollingDropdown3">
                <NavDropdown.Item href="#action3">New Sale</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action4">View Sales</NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown title="Payments" id="navbarScrollingDropdown4">
                <NavDropdown.Item href="#action3">New Payment</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action4">
                  View Payments
                </NavDropdown.Item>
              </NavDropdown> */}

              <a href="/home" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </Nav>
          ) : (
            <></>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
