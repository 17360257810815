import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import CheckButton from "react-validation/build/button";
import DataService from "../services/user-service";
import AuthService from "../services/auth-service";
import "./login.css";

const Login = () => {
  const { id } = useParams();
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [returned, setReturned] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  useEffect(() => {
    DataService.getUserById(id).then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, [id]);

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(content.username, password).then(
        (response) => {
          setReturned(response.data);
          console.log('login' + returned);
          navigate("/home");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {content.username ? (
        <div className="Auth-form-container">
          <Form className="Auth-form" onSubmit={handleLogin} ref={form}>
            <div className="Auth-form-content">
              <h4 className="Auth-form-title">Hi {content.username}</h4>
              <h6 className="text-info text-center">
                Please confirm your password to proceed!!
              </h6>

              <div className="form-group mt-3">
                <label>Password</label>
                <Input
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </div>
              <div className="form-group mt-3">
                <Button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Proceed</span>
                </Button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </div>
          </Form>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Login;
