import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './custom.css';
import Container from 'react-bootstrap/Container';
import InvoiceForm from './components/InvoiceForm';
import InvoiceList from './components/InvoiceList';
import NavScrollExample from './components/Navbar/NavBar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Login from './services/login';
const App = () => {
 
  return (
    <div>
    <NavScrollExample />
    <div className="App d-flex flex-column align-items-center justify-content-center w-100">  
      <Container>
        <Router>
            <Routes>
            <Route exact path={"/"} element={<Login />}></Route>
            <Route exact path={"/:id"} element={<Login />}></Route>
              <Route  path="/home" element={<Home />}></Route>
              <Route  path="/invoice" element={<InvoiceForm />}></Route>
              <Route  path="/invoiceList" element={<InvoiceList />}></Route>
            </Routes>
          </Router>
      </Container>
    </div>
    </div>
    
  );
}

export default App;
