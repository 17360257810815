import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const GenerateInvoice = () => {
  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL('image/png', 1.0);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [612, 792]
    });
    pdf.internal.scaleFactor = 1;
    const imgProps= pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('invoice-001.pdf');
  });
}

const InvoiceModal = ({
  isOpen,
  setIsOpen,
  invoiceInfo,
  items,
}) => {

  function closeModal() {
    setIsOpen(false);
  }

    return(
      <div>
        <Modal show={isOpen} onHide={closeModal} size="lg" centered>
          <div id="invoiceCapture">
            <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
              <div className="w-100">
                <h4 className="fw-bold my-2">{invoiceInfo.cashierName||'Synchrony Technologies Ltd'}</h4>
                <h6 className="fw-bold text-secondary mb-1">
                  Invoice #: {invoiceInfo.invoiceNo||''}
                </h6>
              </div>
              <div className="text-end ms-4">
                <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
                <h6 className="fw-bold text-secondary">({invoiceInfo.currency}{invoiceInfo.total % 1 === 0 ? invoiceInfo.total : invoiceInfo.total.toFixed(2)})</h6>
              </div>
            </div>
            <div className="p-4">
              <Row className="mb-4">
                <Col md={4}>
                  <div className="fw-bold">From:</div>
                  <div>{invoiceInfo.sellerName||''}</div>
                  <div>{invoiceInfo.cashierEmail||''}</div>
                  <div>{invoiceInfo.cashierAddress||''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold">Billed To:</div>
                  <div>{invoiceInfo.customerName||''}</div>
                  <div>{invoiceInfo.customerEmail||''}</div>
                  <div>{invoiceInfo.customerAddress||''}</div>
                </Col>
                <Col md={2}>
                  <div className="fw-bold mt-2">Date Of Issue:</div>
                  <div>{invoiceInfo.dueDate||''}</div>
                </Col>
                <Col md={2}>
                  <div className="fw-bold mt-2">Due Date:</div>
                  <div>{invoiceInfo.dueDate||''}</div>
                </Col>
              </Row>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th>QTY</th>
                    <th>DESCRIPTION</th>
                    <th className="text-end">PRICE</th>
                    <th className="text-end">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) =>  {
                    return (
                      <tr id={item.id} key={item.id}>
                        <td style={{width: '70px'}}>
                          {item.qty}
                        </td>
                        <td>
                          {item.name} - {item.description}
                        </td>
                        <td className="text-end" style={{width: '100px'}}>{invoiceInfo.currency} {item.price}</td>
                        <td className="text-end" style={{width: '100px'}}>{invoiceInfo.currency} {item.price * item.qty}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{width: '100px'}}>SUBTOTAL</td>
                    <td className="text-end" style={{width: '100px'}}>{invoiceInfo.currency} {invoiceInfo.subtotal.toFixed(2)}</td>
                  </tr>
                  {invoiceInfo.taxRate !== 0.00 &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{width: '100px'}}>TAX</td>
                      <td className="text-end" style={{width: '100px'}}>{invoiceInfo.currency} {invoiceInfo.taxRate.toFixed(2)}</td>
                    </tr>
                  }
                  {invoiceInfo.discountRate !== 0.00 &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{width: '100px'}}>DISCOUNT</td>
                      <td className="text-end" style={{width: '100px'}}>{invoiceInfo.currency} {invoiceInfo.discountRate.toFixed(2)}</td>
                    </tr>
                  }
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{width: '100px'}}>TOTAL</td>
                    <td className="text-end" style={{width: '100px'}}>{invoiceInfo.currency} {invoiceInfo.total.toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
              {invoiceInfo.notes &&
                <div className="bg-light py-3 px-4 rounded">
                  {invoiceInfo.notes}
                </div>}
            </div>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6}>
                <Button variant="primary" className="d-block w-100" onClick={GenerateInvoice}>
                  <BiPaperPlane style={{width: '15px', height: '15px', marginTop: '-3px'}} className="me-2"/>Send Invoice
                </Button>
              </Col>
              <Col md={6}>
                <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={GenerateInvoice}>
                  <BiCloudDownload style={{width: '16px', height: '16px', marginTop: '-3px'}} className="me-2"/>
                  Download Copy
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3"/>
      </div>
    )
  
}

export default InvoiceModal;
