import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const Home = () => {
  const data = {
    labels: [
      "may 12",
      "",
      "may 13",
      "",
      "may 14",
      "",
      "may 15",
      "",
      "may 16",
      "",
      "may 17",
      "",
      "may 18",
      "",
    ],
    datasets: [
      {
        data: [8, 9, 7.8, 7.9, 6, 7, 8, 6, 5, 7, 8, 5, 8, 6],
        backgroundColor: "transparent",
        borderColor: "#f26c6d",
        pointingBorderColor: "transparent",
        pointingBorderWidth: 4,
        tension: 0.5,
      },
    ],
  };
  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 2,
        max: 10,
        ticks: {
          stepSize: 2,
          callback: (value) => value + "k",
        },
        grid: {
          borderDash: [10],
        },
      },
    },
  };
  return (
    <>
      <div style={{ width: "400px", height: "500px" }}>
        <Line data={data} options={options}></Line>
      </div>
    </>
  );
};

export default Home;
