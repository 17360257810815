import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BiTrash } from "react-icons/bi";
import EditableField from "./EditableField";

const InvoiceItem = ({
  id,
  name,
  qty,
  price,
  Tprice,
  description,
  onDeleteItem,
  onEdtiItem,
  currency,
}) => {
  const deleteItemHandler = () => {
    onDeleteItem(id);
  };

  return (
    <>
      <tr>
        <td style={{ width: "100%" }}>
          <EditableField
            onEditItem={(event) => onEdtiItem(event)}
            cellData={{
              type: "text",
              name: "name",
              placeholder: "Item name",
              value: name,
              id: id,
            }}
          />
        </td>
        <td style={{ minWidth: "70px" }}>
          <EditableField
            onEditItem={(event) => onEdtiItem(event)}
            cellData={{
              type: "number",
              name: "qty",
              min: 1,
              step: "1",
              value: qty,
              id: id,
            }}
          />
        </td>
        <td style={{ minWidth: "130px" }}>
          <EditableField
            onEditItem={(event) => onEdtiItem(event)}
            cellData={{
              leading: currency,
              type: "number",
              name: "price",
              min: 1,
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: price,
              id: id,
            }}
          />
        </td>
        <td style={{ minWidth: "130px" }}>
          <EditableField
            onEditItem={(event) => onEdtiItem(event)}
            cellData={{
              leading: currency,
              type: "number",
              name: "Tprice",
              min: 1,
              step: "0.01",
              presicion: 2,
              textAlign: "text-end",
              value: Tprice.toFixed(2),
              id: id,
            }}
          />
        </td>
        <td className="text-center" style={{ minWidth: "50px" }}>
          <BiTrash
            onClick={deleteItemHandler}
            style={{ height: "33px", width: "33px", padding: "7.5px" }}
            className="text-white mt-1 btn btn-danger"
          />
        </td>
      </tr>
      <tr>
        <td>
          <EditableField
            onEditItem={(event) => onEdtiItem(event)}
            cellData={{
              type: "text",
              name: "description",
              placeholder: "Item description",
              value: description,
              id: id,
            }}
          />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </>
  );
};

export default InvoiceItem;
