import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://marketsalesapp.com:8443/api/pst/";

const getUserById = (customerId) => {
  return axios.get(API_URL + "customer/" + customerId, { headers: authHeader() })}

const saveInvoice = (invoice) => {
  return axios.post(API_URL + "SaveInvoice/", invoice, { headers: authHeader() })}

  const getInvoiceInfo = () => {
    return axios.get(API_URL + "getInfo/", { headers: authHeader() })}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserById,
  saveInvoice,
  getInvoiceInfo

};