import React, { useState, useEffect } from "react";
import { uid } from "uid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InvoiceItem from "./InvoiceItem";
import InvoiceModal from "./InvoiceModal";
import InputGroup from "react-bootstrap/InputGroup";
import incrementString from "./helpers/incrementString";
//import { genId } from "./helpers/genId";
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";
const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});

const InvoiceForm = () => {

  const [currentUser, setCurrentUser] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [frequency, setFrequency] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  //const sellerName = currentUser.first_name + " " + currentUser.last_name;
  const cashierFname = currentUser.first_name;
  const cashierLname = currentUser.last_name;
  const cashierEmail = currentUser.email;
  const cashierAddress = currentUser.phone;
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [currency, setCurrency] = useState("Ksh ");
  const [dueDate, setDueDate] = useState(today);
  const [notes, setNotes] = useState("");
  const [invoice, setInvoice] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState("0000");
  const [submitted, setSubmitted] = useState(false);
  const [items, setItems] = useState([
    {
      id: uid(6),
      name: "",
      qty: 1,
      price: "1.00",
      currency: currency,
    },
  ]);
  //const uniqueId = genId().toString().slice(8, 13);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const saveInvoice = (event) => {
    console.log('handleSubmit ran');
    event.preventDefault();
    var data = {
      invoice: {
        entityCode: currentUser.id,
        custCode: "LmvvCUT6iy",
        createdBy: currentUser.first_name,
        invoiceDetails: [
          {
            productCode: "1136",
            description: "description1136",
          },
        ],
      },
    };

    UserService.saveInvoice(data)
      .then((response) => {
        setInvoice({
         
            invId: response.data.invId,
            entityCode: response.data.entityCode,
            custCode: response.data.custCode,
            createdBy: response.data.createdBy,
            invoiceDetails: [
              {
                productCode: response.data.productCode,
                description: response.data.description,
              },
            ],
        
        });
        setInvoiceNo(response.data.invId)
        setSubmitted(true);
        reviewInvoiceHandler();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reviewInvoiceHandler = () => {
    // event.preventDefault();
    setIsOpen(true);
  };

  const addNextInvoiceHandler = () => {
    setInvoiceNumber((prevNumber) => incrementString(prevNumber));
    setItems([
      {
        id: uid(6),
        name: "",
        qty: 1,
        price: "1.00",
        currency: currency,
      },
    ]);
  };

  const addItemHandler = () => {
    const id = uid(6);
    setItems((prevItem) => [
      ...prevItem,
      {
        id: id,
        name: "",
        qty: 1,
        price: "1.00",
        currency: currency,
      },
    ]);
  };

  const deleteItemHandler = (id) => {
    setItems((prevItem) => prevItem.filter((item) => item.id !== id));
  };

  const edtiItemHandler = (event) => {
    const editedItem = {
      id: event.target.id,
      name: event.target.name,
      value: event.target.value,
    };

    const newItems = items.map((items) => {
      for (const key in items) {
        if (key === editedItem.name && items.id === editedItem.id) {
          items[key] = editedItem.value;
        }
      }
      return items;
    });

    setItems(newItems);
  };
  
  const subtotal = items.reduce((prev, curr) => {
    if (curr.name.trim().length > 0)
      return prev + Number(curr.price * Math.floor(curr.qty));
    else return prev;
  }, 0);
  const discountRate = (discount * subtotal) / 100;
  const taxRate = (tax * (subtotal - discountRate)) / 100;
  const total = subtotal - discountRate + taxRate;

  return (
    <Form onSubmit={saveInvoice}>
      <Row>
        <Col md={8} lg={9}>
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <div className="d-flex flex-row align-items-start justify-content-between mb-3">
              <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div className="mb-2">
                    <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                    <span className="current-date">{today}</span>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                  <Form.Control
                    type="date"
                    value={dueDate}
                    name={"dateOfIssue"}
                    onChange={(event) => setDueDate(event.target.value)}
                    style={{
                      maxWidth: "150px",
                    }}
                    required="required"
                  />
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <span className="fw-bold me-2">Invoice&nbsp;Number:&nbsp;</span>
                <Form.Control
                  type="text"
                  defaultValue={invoiceNo}
                  name="invoiceNumber"
                  readOnly
                  min="#"
                  style={{
                    maxWidth: "75px",
                  }}
                />
              </div>
            </div>
            <hr className="my-4" />
            <Row className="mb-5">
              <Col>
                <Form.Label className="fw-bold">Bill From:</Form.Label>
                <Form.Control
                  placeholder={"Who is this invoice to?"}
                  defaultValue={cashierFname?  `${cashierFname}  ${cashierLname}` : ''}
                  type="text"
                  name="billTo"
                  className="my-2"
                  autoComplete="name"
                  readOnly
                />
                <Form.Control
                  placeholder={"Email address"}
                  defaultValue={cashierEmail}
                  type="email"
                  name="billToEmail"
                  className="my-2"
                  autoComplete="email"
                  readOnly
                />
                <Form.Control
                  placeholder={"Billing address"}
                  defaultValue={cashierAddress}
                  type="text"
                  name="billToAddress"
                  className="my-2"
                  autoComplete="address"
                  readOnly
                />
              </Col>
              <Col>
                <Form.Label className="fw-bold">Bill To:</Form.Label>
                <Form.Control
                  placeholder={"Who is this invoice from?"}
                  rows={3}
                  value={customerName}
                  type="text"
                  name="billFrom"
                  className="my-2"
                  onChange={(event) => setCustomerName(event.target.value)}
                  autoComplete="name"
                  required="required"
                />
                <Form.Control
                  placeholder={"Email address"}
                  value={customerEmail}
                  type="email"
                  name="billFromEmail"
                  className="my-2"
                  onChange={(event) => setCustomerEmail(event.target.value)}
                  autoComplete="email"
                  required="required"
                />
                <Form.Control
                  placeholder={"Billing address"}
                  value={customerAddress}
                  type="text"
                  name="billFromAddress"
                  className="my-2"
                  autoComplete="address"
                  onChange={(event) => setCustomerAddress(event.target.value)}
                  required="required"
                />
              </Col>
            </Row>
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>QTY</th>
                    <th>UNIT PRICE</th>
                    <th>PRICE</th>
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => (
                    <InvoiceItem
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      qty={item.qty}
                      currency={currency}
                      price={item.price}
                      Tprice={item.qty * item.price}
                      onDeleteItem={deleteItemHandler}
                      onEdtiItem={edtiItemHandler}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="sticky-top pt-md-3 pt-xl-4">
              <Button className="fw-bold" onClick={addItemHandler}>
                Add Item
              </Button>
            </div>
            <Row className="mt-4 justify-content-end">
              <Col lg={6}>
                <div className="d-flex flex-row align-items-start justify-content-between">
                  <span className="fw-bold">Subtotal:</span>
                  <span>
                    {currency}
                    {subtotal.toFixed(2)}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Discount:</span>
                  <span>
                    ({discount || "0"}%){currency}
                    {discountRate.toFixed(2)}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Tax:</span>
                  <span>
                    ({tax || "0"}%){currency}
                    {taxRate.toFixed(2)}
                  </span>
                </div>
                <hr />
                <div
                  className="d-flex flex-row align-items-start justify-content-between"
                  style={{
                    fontSize: "1.125rem",
                  }}
                >
                  <span className="fw-bold">Total:</span>
                  <span className="fw-bold">
                    {" "}
                    {currency}
                    {total % 1 === 0 ? total : total.toFixed(2)}
                  </span>
                </div>
              </Col>
            </Row>
            <hr className="my-4" />
            <Form.Label className="fw-bold">Notes:</Form.Label>
            <Form.Control
              placeholder="Thanks for your business!"
              name="notes"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              as="textarea"
              className="my-2"
              rows={1}
            />
          </Card>
        </Col>
        <Col md={4} lg={3}>
          <div className="pt-md-3 pt-xl-4">
            <InvoiceModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              invoiceInfo={{
                invoiceNumber,
                invoiceNo,
                cashierFname,
                cashierLname,
                cashierEmail,
                cashierAddress,
                customerName,
                customerEmail,
                customerAddress,
                subtotal,
                taxRate,
                discountRate,
                total,
                dueDate,
                notes,
                currency,
              }}
              items={items}
              onAddNextInvoice={addNextInvoiceHandler}
            />
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Currency:</Form.Label>
              <Form.Select
                onChange={(event) => setCurrency(event.target.value)}
                className="btn btn-light my-1"
                aria-label="Change Currency"
              >
                <option value="Ksh ">Ksh. (Kenyan Shillings)</option>
                <option value="$">USD (United States Dollar)</option>
                <option value="£">GBP (British Pound Sterling)</option>
                <option value="¥">JPY (Japanese Yen)</option>
                <option value="$">CAD (Canadian Dollar)</option>
                <option value="$">AUD (Australian Dollar)</option>
                <option value="$">SGD (Signapore Dollar)</option>
                <option value="¥">CNY (Chinese Renminbi)</option>
                <option value="₿">BTC (Bitcoin)</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Frequency:</Form.Label>
              <Form.Select
                onChange={(event) => setFrequency(event.target.value)}
                className="btn btn-light my-1"
                aria-label="Change Currency"
              >
                <option value="Ksh ">Weekly</option>
                <option value="$">Monthly</option>
                <option value="£">Half-Yearly</option>
                <option value="¥">Annually</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label className="fw-bold">Discount rate:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control
                  name="discountRate"
                  type="number"
                  value={discount}
                  onChange={(event) => setDiscount(event.target.value)}
                  className="bg-white border"
                  placeholder="0.0"
                  min="0.00"
                  step="0.01"
                  max="100.00"
                />
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label className="fw-bold">Tax rate:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control
                  name="taxRate"
                  type="number"
                  value={tax}
                  onChange={(event) => setTax(event.target.value)}
                  className="bg-white border"
                  placeholder="0.0"
                  min="0.00"
                  step="0.01"
                  max="100.00"
                />
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Button variant="primary" type="submit" className="d-block w-100">
              Review Invoice
            </Button>
          </div>
          {submitted ? <div className="text-success mt-3"> submitted successfully</div> : <></>}
        </Col>
      </Row>
    </Form>
  );
};

export default InvoiceForm;
